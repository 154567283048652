import IzmasCorner from './components/IzmasCorner';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState} from 'react';

function App() {
  const outerColumnStyle = {
    display: 'flex', // Use flex display
    flexDirection: 'column', // Arrange columns vertically
    border: '1.5px solid #9E7462', // Outer border style with color #282c34
    padding: '10px', // Add padding for space inside the outer boxes
    margin: '10px', // Add margin for space between the outer boxes
  };

  const middleColumnStyle = {
    display: 'flex', // Use flex display
    flex: '1', // Allow middle columns to expand equally
    border: '1px solid #9E7462', // Middle border style with color #282c34
    padding: '5px', // Add padding for space inside the middle boxes
  };

  const innerColumnStyle = {
    border: '1px solid #9E7462', // Inner border style with color #282c34
    padding: '10px', // Add padding for space inside the inner boxes
    width: '100%', // Fixed width of inner box (adjust as needed)
  };

  const italicText = {
    fontStyle: 'italic', // Italicized text
    fontSize: 'x-small', // Smaller font size
  };

  return (
    <div style={outerColumnStyle}>
      <div style={middleColumnStyle}>
        <div style={innerColumnStyle} className="App">
          <div className='title'>
            <h1 className='site-name'>Izma's Corner</h1>
            <IzmasCorner />
            <p>Refresh the page to generate a new set of responses!</p>
            <p style={italicText}>
              This website was made for Izma Shabbir as part of Mollie Redman's Present Form Project in Design 301 during the Fall of 2023.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;