import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OpenAI from 'openai';


const IzmassCorner = () => {
  const [date, setDate] = useState('');
  const [location, setLocation] = useState('');
  const [weather, setWeather] = useState('');
  const [temp, setTemp] = useState('');
  const [funFact, setFunFact] = useState('');
  const [plantsInSeason, setPlantsInSeason] = useState('');
  const [recipeIdeas, setRecipeIdeas] = useState('');

  //require('dotenv').config();
  // const { OpenAIApi } = require('openai');
  // const configuration = new Configuration({
  //   apiKey: process.env.OPENAI_API_KEY,
  // });

  const openai = new OpenAI({
    apiKey: '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', dangerouslyAllowBrowser: true 
  });

  const fetchData = async (locationParam, dateParam, weatherParam) => {
    try {
      const funFactParams = {
        messages: [{
          role:'user', 
          content: `Give me a random fun fact about ${locationParam} that is less than 250 characters.`,
        }],
        model: 'gpt-3.5-turbo',
      };
      const chatCompletion1 = await openai.chat.completions.create(funFactParams);
      setFunFact(chatCompletion1.choices[0].message.content);

      const plantsInSeasonParams = {
        messages: [{
          role:'user', 
          content: `Tell me 3 local plants in season in ${locationParam} in ${dateParam} and tell me what to look for. use less than 250 characters and display your answer like "1. Plant A, charcteristic to look for" and so on.`,
        }],
        model: 'gpt-3.5-turbo',
      };
      const chatCompletion2 = await openai.chat.completions.create(plantsInSeasonParams);
      setPlantsInSeason(chatCompletion2.choices[0].message.content);

      const recipeIdeasParams = {
        messages: [{
          role:'user', 
          content: `Give me 1 recipe idea given it is ${dateParam} and the weather ${weatherParam}, tell me an idea about how to elevate it, state the idea concisely, and use less than 250 characters`,
        }],
        model: 'gpt-3.5-turbo',
      };
      const chatCompletion3 = await openai.chat.completions.create(recipeIdeasParams);
      setRecipeIdeas(chatCompletion3.choices[0].message.content);
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // console.log(weatherParam,'tester');
  };
  // console.log(location,'test');
  
  useEffect(() => {
    let city, state, country; // Declare variables outside try-catch scope
  
    const fetchDataAndProcess = async () => {
      try {
        const currentDate = new Date().toLocaleDateString();
        setDate(currentDate);
  
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
  
        const { latitude, longitude } = position.coords;
  
        const locationResponse = await axios.get(
          `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
        );
        const addressArray = locationResponse.data.display_name.split(', ');
        city = addressArray.length > 2 ? addressArray[addressArray.length - 5] : '';
        state = addressArray.length > 4 ? addressArray[addressArray.length - 3] : '';
        country = addressArray.length > 6 ? addressArray[addressArray.length - 1] : '';
        setLocation(`${city}, ${state}, ${country}`);
        console.log(addressArray);
        console.log(addressArray[addressArray.length-1]);
        const weatherResponse = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=bccf4b5b2385ca13632b7cd4ebd768fb`
        );
        setWeather(weatherResponse.data.weather[0].description);
        setTemp(Math.round((weatherResponse.data.main.temp - 273.15) * 9 / 5 + 32) + '\u00B0F');
  
        // Call fetchData and pass the weather description parameter here
        fetchData(`${city}, ${state}, ${country}`, currentDate, weatherResponse.data.weather[0].description + Math.round((weatherResponse.data.main.temp - 273.15) * 9 / 5 + 32) + ' ' + '\u00B0F' );
      } catch (error) {
        console.error('Error getting location/weather details:', error);
        setLocation('Location not found');
        setWeather('Weather not found.');
      }
    };
  
    fetchDataAndProcess(); // Call fetchDataAndProcess function
  }, []);

  const outerColumnStyle = {
    display: 'flex', // Use flex display
    flexDirection: 'column', // Arrange columns vertically
    border: '1.5px solid #9E7462', // Outer border style with color #282c34
    padding: '10px', // Add padding for space inside the outer boxes
    margin: '10px', // Add margin for space between the outer boxes
  };

  const middleColumnStyle = {
    display: 'flex', // Use flex display
    flex: '1', // Allow middle columns to expand equally
    border: '1px solid #9E7462', // Middle border style with color #282c34
    padding: '5px', // Add padding for space inside the middle boxes
    // backgroundColor: '#f0f0f0', // Background color for middle boxes
  };

  const innerColumnStyle = {
    border: '1px solid #9E7462', // Inner border style with color #282c34
    padding: '10px', // Add padding for space inside the inner boxes
    width: '100%', // Fixed width of inner box (adjust as needed)
  };

  return (
    <div>
      <p>Date: {date}</p>
      <p>User's Location: {location}</p>
      <p>Weather: {weather}, {temp}</p>
      <div className="container text-center">
        <div className="row">
          <div className="col" style={outerColumnStyle}>
            <div style={middleColumnStyle}>
              <div style={innerColumnStyle}>
                <h2>Fun Fact:</h2>
                <p>{funFact}</p>
              </div>
            </div>
          </div>
          <div className="col" style={outerColumnStyle}>
            <div style={middleColumnStyle}>
              <div style={innerColumnStyle}>
                <h2>Plants in Season:</h2>
                <p>{plantsInSeason}</p>
              </div>
            </div>
          </div>
          <div className="col" style={outerColumnStyle}>
            <div style={middleColumnStyle}>
              <div style={innerColumnStyle}>
                <h2>Recipe Ideas:</h2>
                <p>{recipeIdeas}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IzmassCorner;
